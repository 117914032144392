import React from "react"
import styles from "./formularioContacto.module.css"

const FormularioContacto = () => {
    return (
        <div>
            <h2 className={styles.titulo}>Contáctanos</h2>
            <p className={styles.subtitulo}>Envíanos un mensaje con tus dudas</p>
            <form
                className={styles.formulario}
                method="post"
                action="/contacto_success"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="contact"
            >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <input type="text" name="name" id="name" placeholder="Nombres *" />
                <input type="text" name="last_name" id="last_name" placeholder="Apellidos *" />
                <input type="email" name="email" id="email" placeholder="Correo electrónico *" />
                <input type="text" name="establishment" placeholder="Institución Educativa" id="establishment" />
                <textarea name="message" id="message" rows="5" placeholder="Motivo de Contacto *" />
                <button className={"button-primary"} type="submit">
                    Enviar
                </button>
            </form>
        </div>
    )
}

export default FormularioContacto
